import { FC, useMemo, useState } from "react";
import { formatAppNameText } from "@finbackoffice/fe-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { SkinVersions } from "@finbackoffice/site-server-core";
import Img from "components/base/img/Img";
import Translate from "components/base/translate/Translate";
import Step1NoSwitch from "./Step1NoSwitch";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Completed from "./Completed";
import styles from "./forgot-pass.module.sass";

type ForgotPassVariant = "default" | "no_switch";

type IForgotPassProps = {
    onComplete: () => void;
    variant?: ForgotPassVariant;
};

const ForgotPass: FC<IForgotPassProps> = ({ variant = "default", onComplete }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const [tempId, setTempId] = useState<string>("");
    const [code, setCode] = useState<string>("");

    const [step, setStep] = useState<number>(1);

    const renderStep = useMemo(() => {
        switch (step) {
            case 1:
                if (variant === "no_switch") {
                    return (
                        <Step1NoSwitch
                            setStep={setStep}
                            setTempId={setTempId}
                            id={tempId}
                            setCode={setCode}
                        />
                    );
                } else {
                    return (
                        <Step1
                            setStep={setStep}
                            setTempId={setTempId}
                            id={tempId}
                            setCode={setCode}
                        />
                    );
                }
            case 2:
                return <Step2 id={tempId} code={code} setStep={setStep} />;
            case 3:
                return <Completed onComplete={onComplete} />;
            default:
                return null;
        }
    }, [code, onComplete, step, tempId, variant]);

    return (
        <div className={styles.forgotPass}>
            {variant === "default" && (
                <div>
                    {COMMON_SITE_CONFIGS.skinVersion === SkinVersions.Betmidas ? (
                        <Img
                            source={`${ASSETS_URL}/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/desktop/logo.svg`}
                            alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                            title={`${COMMON_SITE_CONFIGS.appName} logo`}
                            width={0}
                            height={0}
                            style={{ width: "100%", height: "auto" }}
                        />
                    ) : (
                        <Translate tid="forgot_password_head" />
                    )}
                </div>
            )}

            {renderStep}
        </div>
    );
};

export default ForgotPass;
