import { FC, useContext } from "react";
import { UserStatus } from "@finbackoffice/enums";
import { ConfigContext, ModalsContext, UserAccountContext } from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import VerificationItem from "./item/VerificationItem";
import styles from "./verification.module.sass";

const Verification: FC = () => {
    const { userData } = useContext(UserAccountContext);
    const { siteRegistrationConfig } = useContext(ConfigContext);
    const { setCurrentModal } = useContext(ModalsContext);

    const onFinishClickHandler = async () => {
        if (userData?.status !== UserStatus.Draft) {
            setCurrentModal(null);
        }
    };

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <Translate tid="verification_heading" />
            </div>
            <div className={styles.verification} data-testid="verification-container">
                {userData?.status === UserStatus.Draft && (
                    <span className={styles.pleaseVerifyTxt}>
                        <Translate tid="verification_text" />
                    </span>
                )}
                {userData && siteRegistrationConfig ? (
                    <>
                        {siteRegistrationConfig.require_email_verification && (
                            <VerificationItem type="email" verified={userData.email_verified}>
                                <Translate
                                    tid="verification_emailDescription"
                                    replace={{ email: userData.email }}
                                    dangerous>
                                    <div />
                                </Translate>
                            </VerificationItem>
                        )}
                        {siteRegistrationConfig.require_phone_verification && (
                            <VerificationItem type="phone" verified={userData.phone_verified}>
                                <Translate
                                    tid="verification_phoneDescription"
                                    replace={{ phone: userData.phone }}
                                    dangerous>
                                    <div />
                                </Translate>
                            </VerificationItem>
                        )}
                    </>
                ) : (
                    <Loading />
                )}

                <Button
                    type="button"
                    variant="primary"
                    onClick={onFinishClickHandler}
                    disabled={userData?.status === UserStatus.Draft}
                    className={styles.finishButton}>
                    <Translate tid="verification_finish" />
                </Button>
            </div>
        </section>
    );
};

export default Verification;
