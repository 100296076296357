import { Currency } from "@finbackoffice/enums";
import { CurrencyShortNames } from "@finbackoffice/fe-core";
import { FC, useContext } from "react";
import {
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Translate from "components/base/translate/Translate";
import { Chars } from "utils/strings";
import styles from "./exchange-rate-panel.module.sass";

const ExchangeRatePanel: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { siteDefaultCurrency } = useContext(ConfigContext);
    const { currentCurrency, userCurrency } = useContext(UserAccountContext);
    const {
        exchangeRatesIsReady,
        usdExchangeRate: usdRate,
        defaultCurrencyExchangeRate,
        fxCurrencyExchangeRate,
    } = useContext(ExchangeRatesContext);
    const isDefaultCurrencyActive = currentCurrency === siteDefaultCurrency;
    const isKRW = currentCurrency === Currency.KRW;
    const minAmount = isKRW ? 1000 : 1;
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;

    if (!exchangeRatesIsReady) {
        return null;
    }

    return (
        <FadeInAnimation>
            <span className={styles.fxRate}>
                <Translate tid="fx_rate" />
                {currentCurrency && (
                    <>
                        <strong>
                            {minAmount} {CurrencyShortNames[currentCurrency] || currentCurrency}
                        </strong>
                        ≈
                        {!!((defaultCurrencyExchangeRate || fxCurrencyExchangeRate) && usdRate) && (
                            <span>
                                {fiatEnabled && !isDefaultCurrencyActive && (
                                    <>
                                        <CurrencyFormatter
                                            currency={userCurrency || siteDefaultCurrency}
                                            amount={defaultCurrencyExchangeRate.toString()}
                                            withCode={false}
                                            withSymbol
                                        />
                                        {Chars.nbsp}/{Chars.nbsp}
                                    </>
                                )}
                                <CurrencyFormatter
                                    currency={
                                        fiatEnabled
                                            ? Currency.USD
                                            : COMMON_SITE_CONFIGS.wallet.fxCurrency
                                    }
                                    amount={(
                                        (fiatEnabled ? usdRate : fxCurrencyExchangeRate) * minAmount
                                    ).toString()}
                                    withCode={false}
                                    withSymbol
                                />
                            </span>
                        )}
                    </>
                )}
            </span>
        </FadeInAnimation>
    );
};

export default ExchangeRatePanel;
