import { ModalsContext } from "@finbackoffice/site-core";
import { FC, useContext, useState, useCallback, useEffect } from "react";
import AutoHeight from "components/base/auto-height/AutoHeight";
import Modal from "components/base/modal/Modal";
import { ModalTypes } from "utils/constants";
import { LoginViewType } from "./Header";
import ForgotPass from "./login-panel/forgot-pass/ForgotPass";
import LoginForm from "./login-panel/form/LoginForm";
import styles from "./header.module.sass";

type IProps = {
    defaultView?: LoginViewType;
};

export const LoginModal: FC<IProps> = ({ defaultView }) => {
    const { loginModalRef } = useContext(ModalsContext);
    const [currentView, setCurrentView] = useState<LoginViewType>(defaultView || "login");

    const onModalClose = useCallback(() => {
        setCurrentView("login");
    }, []);

    useEffect(() => {
        if (defaultView) {
            setCurrentView(defaultView);
        }
    }, [defaultView]);

    return (
        <Modal
            ref={loginModalRef}
            styleClass={styles.loginModal}
            type={ModalTypes.LOGIN}
            maskClosable={false}
            onClose={onModalClose}>
            <AutoHeight duration={300}>
                {currentView === "login" ? (
                    <LoginForm setCurrentView={setCurrentView} />
                ) : (
                    <ForgotPass onComplete={() => setCurrentView("login")} />
                )}
            </AutoHeight>
        </Modal>
    );
};
