import { CountryISO2Mapping } from "@finbackoffice/fe-core";
import classnames from "classnames";
import {
    ConfigContext,
    ITranslateProps,
    useDidUpdateEffect,
    useRuntimeConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import {
    FieldError,
    FieldErrorsImpl,
    Merge,
    UseFormClearErrors,
    UseFormSetError,
} from "react-hook-form";
import IntlTelInput, { CountryData } from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Country, TranslationScopes } from "@finbackoffice/enums";
import Translate from "../translate/Translate";
import styles from "./phone-input.module.sass";

type IProps = {
    label?: string | ITranslateProps;
    defaultCountry?: string;
    enableAutoComplete?: boolean;
    enablePlaceholder?: boolean;
    separateDialCode?: boolean;
    disabled?: boolean;
    isCountryDisabled?: boolean;
    required?: boolean;
    value?: string;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    onChange: (val: string) => void;
    setError: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
    wrapperClassname?: string;
    testId?: string;
};

const PhoneInput: FC<IProps> = ({
    enableAutoComplete,
    enablePlaceholder = true,
    disabled,
    isCountryDisabled = false,
    required,
    value,
    onChange,
    wrapperClassname,
    separateDialCode,
    error,
    setError,
    clearErrors,
    label,
    testId,
}) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { t } = useTranslation();
    const { siteClientCountry } = useContext(ConfigContext);
    const [number, setNumber] = useState(value);
    const [dialCode, setDialCode] = useState("");
    const defaultCountryMemo = useMemo(
        () =>
            !value
                ? siteClientCountry.isoAlpha2.toLowerCase()
                : CountryISO2Mapping[COMMON_SITE_CONFIGS.country.default as Country],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const supportedCountries = useMemo(
        () =>
            COMMON_SITE_CONFIGS.country.supported.map(
                (country) => CountryISO2Mapping[country as Country],
            ),
        [COMMON_SITE_CONFIGS.country.supported],
    );

    useDidUpdateEffect(() => {
        onChange(`${separateDialCode && dialCode ? `+${dialCode}` : ""}${number}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialCode, number]);

    const onPhoneNumberChangeHandler = useCallback(
        (
            valid: boolean,
            value: string,
            country: CountryData,
            fullNumber: string,
            onChange: (...event: any[]) => void,
        ) => {
            let newValue: string = value;
            if (valid) {
                if (!separateDialCode) {
                    if (country.dialCode && !newValue.includes(country.dialCode)) {
                        newValue = fullNumber.replaceAll(" ", "");
                    }
                }

                clearErrors("root.apiError");
            } else {
                if (!separateDialCode) {
                    if (country.dialCode && !newValue.includes(country.dialCode)) {
                        newValue = `+${country.dialCode}`;
                    }
                }

                if (!required && !value.trim()) {
                    clearErrors("root.apiError");
                } else {
                    setError("root.apiError", {
                        type: "notValid",
                        message: t("validation_err_notValid"),
                    });
                }
            }

            if (separateDialCode) {
                setDialCode(country.dialCode ?? "");
            }

            onChange(newValue);
        },
        [clearErrors, required, separateDialCode, setError, t],
    );

    const onPhoneNumberFocusHandler = (
        valid: boolean,
        value: string,
        country: CountryData,
        _fullNumber: any,
        onChange: any,
    ) => {
        let newValue: string = value;
        if (!newValue) {
            if (!separateDialCode) {
                newValue = `+${country.dialCode}`;
                onChange(newValue);
            }

            if (!valid) {
                setError("root.apiError", {
                    type: "notValid",
                    message: t("validation_err_notValid"),
                });
            }
        }
    };

    const onPhoneNumberBlurHandler = (
        valid: boolean,
        _value: string,
        country: CountryData,
        fullNumber: any,
        onChange: any,
    ) => {
        if (valid) {
            const formatedNumber = fullNumber.replaceAll("-", "").replaceAll(" ", "").trim();
            if (separateDialCode) {
                onChange(formatedNumber.replace(`+${country.dialCode}`, ""));
            } else {
                onChange(formatedNumber);
            }
        }
    };

    const handleFlagChange = (
        _currentNumber: string,
        selectedCountryData: CountryData,
        _fullNumber: string,
        isValid: boolean,
    ) => {
        if (isValid) {
            if (separateDialCode) {
                setDialCode(selectedCountryData.dialCode ?? "");
                clearErrors("root.apiError");
            }
        } else if (value) {
            setError("root.apiError", {
                type: "notValid",
                message: t("validation_err_notValid"),
            });
        }
    };

    return (
        <div
            className={classnames(
                styles.phoneInput,
                supportedCountries.length === 1 && styles.oneCountry,
                wrapperClassname,
            )}
            data-testid={testId}>
            {label && (
                <span className="intl-tel-input-label">
                    <Translate
                        tid={typeof label === "string" ? label : label.tid}
                        namespace={
                            typeof label !== "string" ? label.namespace : TranslationScopes.Common
                        }
                    />
                </span>
            )}
            <IntlTelInput
                allowDropdown={!isCountryDisabled && supportedCountries.length > 1}
                fieldName="phone"
                autoComplete={enableAutoComplete ? "on" : "off"}
                disabled={disabled}
                containerClassName={classnames(
                    "intl-tel-input",
                    required && "required",
                    error && styles.fieldError,
                )}
                inputClassName="phone-widget"
                nationalMode={false}
                onSelectFlag={handleFlagChange}
                value={number}
                formatOnInit={false}
                onlyCountries={supportedCountries}
                defaultCountry={defaultCountryMemo || undefined}
                preferredCountries={[
                    CountryISO2Mapping[COMMON_SITE_CONFIGS.country.default as Country],
                ]}
                separateDialCode={separateDialCode}
                onPhoneNumberBlur={(valid, val, country, fullNumber) =>
                    onPhoneNumberBlurHandler(valid, val, country, fullNumber, setNumber)
                }
                onPhoneNumberFocus={(valid, val, country, fullNumber) =>
                    onPhoneNumberFocusHandler(valid, val, country, fullNumber, setNumber)
                }
                onPhoneNumberChange={(valid, val, country, fullNumber) =>
                    onPhoneNumberChangeHandler(valid, val, country, fullNumber, setNumber)
                }
                autoPlaceholder={enablePlaceholder}
            />

            {error && (
                <span className={classnames(styles.error, "field-error")}>
                    {error.message?.toString()}
                </span>
            )}
        </div>
    );
};

export default PhoneInput;
