import { FC } from "react";
import Button from "components/base/button/Button";
import Translate from "components/base/translate/Translate";
import styles from "./forgot-pass.module.sass";

type ICompletedProps = {
    onComplete: () => void;
};

const Completed: FC<ICompletedProps> = ({ onComplete }) => {
    return (
        <div className={styles.forgotPassCompleted}>
            <p>Your password was successfully changed!</p>
            <Button type="text" onClick={onComplete}>
                <Translate tid="forgot_password_login" />
            </Button>
        </div>
    );
};

export default Completed;
