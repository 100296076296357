import { ISiteConfigLayouts, SignupLayoutEnum } from "@finbackoffice/fe-core";
import {
    useSiteConfig,
    ConfigContext,
    ModalsContext,
    CpfSignupContext,
} from "@finbackoffice/site-core";
import { FC, useContext, useMemo } from "react";
import Modal from "components/base/modal/Modal";
import { ModalTypes } from "utils/constants";
import Signup from "components/account/signup/Signup";
import styles from "./header.module.sass";

const SignupModal: FC = () => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { siteRegistrationConfig } = useContext(ConfigContext);
    const { signupModalRef } = useContext(ModalsContext);
    const { step } = useContext(CpfSignupContext);
    const closable = useMemo(() => {
        if (siteLayoutsConfig.signup !== SignupLayoutEnum.CPFSignup) return true;
        return step !== "second" || !siteRegistrationConfig?.auto_id_verification;
    }, [siteLayoutsConfig.signup, step, siteRegistrationConfig]);

    return (
        <Modal
            ref={signupModalRef}
            styleClass={styles.signupModal}
            type={ModalTypes.SIGNUP}
            closable={closable}
            maskClosable={false}>
            <Signup />
        </Modal>
    );
};

export default SignupModal;
