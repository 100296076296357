import React, { FC, useCallback, useContext } from "react";
import { formatAppNameText } from "@finbackoffice/fe-core";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { ModalsContext, useRuntimeConfig } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { Svg } from "components/base/svg/Svg";
import WalletSelector from "../user-panel/WalletSelector";
import styles from "./login-panel.module.sass";

const LoginPanel: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { signupModalRef, loginModalRef } = useContext(ModalsContext);

    const signupButtonClickHandler = useCallback(() => {
        signupModalRef.current?.open();
    }, [signupModalRef]);

    return (
        <div className={styles.login} data-testid="login-panel-header">
            <Svg
                src={`/${formatAppNameText(
                    COMMON_SITE_CONFIGS.appName,
                )}/desktop/icons/header-login-icon.svg`}
                wrapper="span"
                className={styles.userIcon}
            />
            {COMMON_SITE_CONFIGS.skinVersion !== SkinVersions.Betmidas && <WalletSelector />}

            <i className={styles.separator} />
            <Button
                type="button"
                onClick={() => loginModalRef.current?.open()}
                data-testid="header-login-button"
                className={styles.loginBtn}>
                <Translate tid="header_login" />
            </Button>
            <Button
                type="button"
                onClick={signupButtonClickHandler}
                data-testid="signupButton"
                className={styles.registerBtn}>
                <Translate tid="header_registerNow" />
            </Button>
        </div>
    );
};
export default LoginPanel;
