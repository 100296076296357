import { FC, SyntheticEvent, useCallback } from "react";

type Props = {
    name: string;
    checked: boolean;
    onChange: (val: boolean) => void;
    testId?: string;
    disabled?: boolean;
};

const ToggleSwitch: FC<Props> = ({ checked, onChange, name, testId, disabled }) => {
    const handleChange = useCallback(
        (e: SyntheticEvent) => {
            if (!disabled) {
                onChange(!checked);
            } else {
                e.preventDefault();
            }
        },
        [checked, onChange, disabled],
    );
    return (
        <label htmlFor={`${name}-checkbox`} className="ui-switch">
            <input type="checkbox" id={`${name}-checkbox`} readOnly checked={checked} />
            <span className="ui-switch-slider" onClick={handleChange} data-testid={testId} />
        </label>
    );
};
export default ToggleSwitch;
